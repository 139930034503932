<template>
    <div class="page">
        <Header home="true" disconnect="true" />

        <main class="page__main">
            <div class="content">
                <div class="content__header">
                    <div class="content__icon">
                        <span class="icon icon--light"><img src="../assets/images/ic-member.svg" /></span>
                    </div>
                    <div class="content__title">
                        <h1 class="h5">Votre profil utilisateur</h1>
                    </div>
                </div>
                <div class="content__body">
                    <ProfileForm />
                    <ChangeEmailForm />
                    <ChangePasswordForm />

                    <div class="card border-light rounded-3 shadow-lg">
                        <div class="card-body">
                            <h2 class="card-title h4 mb-4 text-secondary">Désactiver votre compte</h2>
                            <p class="card-text">Si vous désactivez votre compte, vous recevrez la version papier du questionnaire.</p>

                            <div
                                :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                                role="alert"
                                v-html="alert ? alert.message : '&nbsp;'"
                            ></div>

                            <button type="button" class="btn btn-secondary rounded-pill" :disabled="loading || (alert && alert.class === 'success')" @click="stopAccount">
                                Désactiver votre compte
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import ProfileForm from '@/components/ProfileForm.vue'
import ChangeEmailForm from '@/components/ChangeEmailForm.vue'
import ChangePasswordForm from '@/components/ChangePasswordForm.vue'

export default {
    name: 'Profile',
    components: {
        Header,
        Footer,
        ProfileForm,
        ChangeEmailForm,
        ChangePasswordForm,
    },
    data: () => ({
        loading: false,
        alert: null,
    }),
    methods: {
        ...mapActions(['disableAccount', 'logOut']),
        async stopAccount() {
            if (confirm('Confirmez-vous la désactivation de votre compte ?')) {
                this.alert = await this.disableAccount()
                if (this.alert.class === 'success') {
                    setTimeout(() => {
                        this.$router.push('/')
                        this.logOut()
                    }, 3000)
                }
            }
        },
    },
}
</script>

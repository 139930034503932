<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary">Vos informations personnelles</h2>
            <dl class="row">
                <dt class="col-sm-3">Numéro Constances</dt>
                <dd class="col-sm-9">{{ getUser.nconstances }}</dd>

                <dt class="col-sm-3">E-mail</dt>
                <dd class="col-sm-9">{{ getUser.mail }}</dd>

                <dt class="col-sm-3">Date de naissance</dt>
                <dd class="col-sm-9">{{ getUser.dt_nais }}</dd>

                <dt class="col-sm-3">Sexe</dt>
                <dd class="col-sm-9">{{ getUser.sexe }}</dd>

                <dt class="col-sm-3">CES du 1<sup>er</sup> bilan de santé</dt>
                <dd class="col-sm-9">{{ getUser.nom_centre }}</dd>

                <dt class="col-sm-3">Inclusion</dt>
                <dd class="col-sm-9">{{ formatInclusion(getUser.period_invit) }}</dd>
            </dl>

            <div v-if="profileLink" class="mt-3">
                <router-link to="/profil" class="btn btn-primary rounded-pill">Gérer votre compte</router-link>
                <p class="small text-muted mt-4">
                    Il n'est pas encore possible de déclarer un changement d'adresse postale à partir de cet espace.
                    <br />Il est cependant <strong>important que cette adresse soit à jour</strong>, même si vous répondez par Internet (par exemple, pour l’envoi de documents ou de matériel dans le
                    cadre d’un projet spécifique). <br />En cas de déménagement (récent ou prochain), pensez à nous en informer au 0805 02 02 63 ou à
                    <a href="mailto:contact@constances.fr">contact@constances.fr</a>, en précisant votre numéro Constances, votre ancienne adresse et la nouvelle.
                </p>
            </div>
            <div v-else>Si les informations ci-dessus ne sont pas correctes, merci de contacter l'équipe Constances à l'adresse <a href="mailto:contact@constances.fr">contact@constances.fr</a></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Tools from '../tools'

export default {
    name: 'ProfileForm',
    props: {
        profileLink: { type: Boolean, default: false },
    },
    mixins: [Tools],
    computed: {
        ...mapGetters(['getUser']),
    },
}
</script>

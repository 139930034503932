<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary">Vos résultats d'examens</h2>
            <div v-if="loading">Chargement...</div>
            <ul v-else-if="analysisResults && analysisResults.length" class="list-group list-group-flush">
                <li class="list-group-item d-none d-md-block">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b>Nom de l'étude</b>
                        </div>
                        <div class="col-12 col-md-4">
                            <b>Date de l'examen</b>
                        </div>
                        <div class="col-12 col-md-2">
                            <b>Résultats</b>
                        </div>
                    </div>
                </li>
                <li v-for="q in analysisResults" :key="q.id" class="list-group-item">
                    <div class="row">
                        <div class="col-12 col-md-6 d-flex align-items-center">{{ q.title }}</div>
                        <div class="col-12 col-md-4 d-flex align-items-center">
                            <small class="mt-2 mt-md-0">{{ formatDate(q.cw_finishdate) }}</small>
                        </div>
                        <div
                            class="col-12 col-md-2 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                            <a href="#" @click="showPopup($event, q.id, q.isFile)">Mes résultats</a>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else-if="analysisResults !== false">Vous n'avez aucun résultat d'examen récent.</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Tools from '../tools'

export default {
    name: 'AnalysisResults',
    mixins: [Tools],
    computed: {
        ...mapState(['loading', 'questionnaires']),
        analysisResults() {
            if (this.questionnaires !== null) {
                return this.questionnaires.filter((q) => q.isAnalysis && q.downloadable)
            }
            return false
        },
    },
    methods: {
        ...mapActions(['toggleDownloadPopup']),
        showPopup(event, qid, isFile) {
            event.preventDefault()
            this.toggleDownloadPopup({ display: true, qid, isFile })
        },
    },
}
</script>
<template>
    <footer class="page__footer">
        <small>© Constances 2022 - <a href="mailto:contact@constances.fr">contact@constances.fr</a></small>
        <span class="fs-n2 text-muted mt-4">v{{ version }}</span>
    </footer>
</template>

<script>
import VERSION from '../version.json'

export default {
    name: 'Footer',
    data: () => ({
        version: VERSION.v,
    }),
}
</script>
import { createStore } from 'vuex'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

export default new createStore({
    state: () => ({
        user: null,
        address: null,
        loading: false,
        questionnaires: null,
        displayPopup: false,
        displayPopupAddress: true,
        downloadId: null,
        isFile: false,
    }),
    getters: {
        isAuth: (state) => state.user !== null,
        getUser: (state) => {
            const user = { ...state.user }
            user.sexe = user.sexe === 1 ? 'Masculin' : 'Féminin'
            return user
        },
    },
    mutations: {
        setUser: (state, payload) => {
            state.user = payload
        },
        setAddress: (state, payload) => {
            state.address = payload
        },
        setLoading: (state, payload) => {
            state.loading = payload
        },
        setQuestionnaires: (state, payload) => {
            state.questionnaires = payload
        },
        setPopupDisplay: (state, payload) => {
            state.displayPopup = payload
        },
        setPopupDisplayAddress: (state, payload) => {
            state.displayPopupAddress = payload
        },
        setDownloadId: (state, payload) => {
            state.downloadId = payload || null
        },
        setIsPdf: (state, payload) => {
            state.isFile = payload || false
        },
    },
    actions: {
        /**
         * Send account activation request (step #1).
         */
        async activateAccount(context, payload) {
            try {
                const res = await axios.post('/activate1', payload)
                return res.data // { token }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Send account settings for activation (step #2).
         */
        async sendAccountSettings(context, payload) {
            try {
                await axios.post('/activate2', payload)
                return { class: 'success', message: 'Un e-mail de confirmation vous a été envoyé' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Confirm activation through email link (step #3).
         */
        async confirmActivation(context, payload) {
            try {
                await axios.post('/activate3', payload)
                return { class: 'success', message: 'Votre compte a bien été activé !' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Log user in checking credentials through API and storing returned token.
         */
        async logIn({ dispatch }, payload) {
            try {
                const res = await axios.post('/login', payload)
                if (res.data) {
                    // store token
                    if (payload.remember) {
                        localStorage.setItem('token', res.data)
                    } else {
                        sessionStorage.setItem('token', res.data)
                    }
                    axios.defaults.headers.common.Authorization = 'Bearer ' + res.data
                    // get user profile
                    await dispatch('getProfile')
                    await dispatch('getAddress')
                }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Log user out clearing stored token and state.
         */
        logOut({ commit }) {
            delete axios.defaults.headers.common.Authorization
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            commit('setUser', null)
            commit('setAddress', null)
        },
        /**
         * Load user profile automatically from existing stored token.
         */
        async initAuth({ dispatch }) {
            try {
                if (localStorage.getItem('token')) {
                    axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token')
                    await dispatch('getProfile')
                    await dispatch('getAddress')
                } else if (sessionStorage.getItem('token')) {
                    axios.defaults.headers.common.Authorization = 'Bearer ' + sessionStorage.getItem('token')
                    await dispatch('getProfile')
                    await dispatch('getAddress')
                }
            } catch (err) {
                console.log('Unexpected error getting profile or address')
            }
        },

        ///////////////
        /// Profile ///
        ///////////////

        /**
         * Get user profile through API and keep it into state.
         */
        async getProfile({ commit }) {
            const res = await axios.get('/user/profile')
            if (res.status === 200) {
                commit('setUser', res.data)
            }
        },
        /**
         * Get user address
         */
        async getAddress({ commit }) {
            const res = await axios.get('/user/address')
            if (res.status === 200) {
                commit('setAddress', res.data)
            }
        },
        /**
         * Get attachment centers list.
         */
        async getAttachCenters() {
            const res = await axios.get('/centers')
            return res.data
        },
        /**
         * Ask for password reset (step #1).
         */
        async resetPassword(context, payload) {
            try {
                await axios.post('/reset-password', payload)
                return { class: 'success', message: 'Un e-mail vous a été envoyé' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Send new password for reset.
         */
        async sendNewPassword(context, payload) {
            try {
                await axios.post('/new-password', payload)
                return { class: 'success', message: 'Votre mot de passe a été modifié' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Send new email address for account update.
         */
        async sendNewEmail(context, payload) {
            try {
                await axios.post('/update-email', payload)
                return { class: 'success', message: 'Un e-mail de confirmation vous a été envoyé' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Confirm email change.
         */
        async confirmNewEmail(context, payload) {
            try {
                await axios.post('/new-email', payload)
                return { class: 'success', message: 'Votre adresse e-mail a bien été modifiée' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Update password from profile.
         */
        async updatePassword(context, payload) {
            try {
                await axios.post('/update-password', payload)
                return { class: 'success', message: 'Votre mot de passe a été modifié' }
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Disable account.
         */
        async disableAccount() {
            try {
                await axios.post('/disable-account')
                return { class: 'success', message: 'Votre compte a été désactivé. Vous allez être déconnecté.' }
            } catch (err) {
                return err.response.data
            }
        },

        /////////////////
        /// Dashboard ///
        /////////////////

        /**
         * Get questionnaires list.
         */
        async getQuestionnaires({ commit, dispatch }) {
            commit('setLoading', true)
            const questionnaires = await axios.get('/get-questionnaires-list')
            let myQuestionnaires = []

            for (const q of questionnaires.data) {
                if (q.isFile) {
                    const fileExists = await dispatch('checkFile', q.id)
                    if (fileExists) {
                        myQuestionnaires.push({
                            id: q.id,
                            title: q.title,
                            isAnalysis: q.isAnalysis,
                            downloadable: true,
                            isFile: true,
                        })
                    }
                } else {
                    const interview = await dispatch('getInterview', q.id)
                    if (interview && interview.is_valid) {
                        myQuestionnaires.push({
                            id: q.id,
                            title: q.title,
                            isAnalysis: q.isAnalysis,
                            startDate: interview.startDate || q.startDate,
                            endDate: interview.endDate || q.endDate,
                            cw_track: interview.CW_track,
                            complete: interview.qstatus == '2',
                            cw_finishdate: interview.CW_finishdate,
                            closed: interview.stop > 0 || interview.closed > 0,
                            downloadable: interview.downloadable > 0,
                            isFile: false,
                        })
                    }
                }
            }
            commit('setLoading', false)
            commit('setQuestionnaires', myQuestionnaires)
        },

        /**
         * Check if downloadable file exist.
         */
        async checkFile(context, qid) {
            try {
                const url = `${process.env.VUE_APP_API_URL}/check-file/${qid}`
                const res = await axios.get(url)
                return res.data
            } catch (err) {
                return console.log(err)
            }
        },

        /**
         * Get CAWI CW_track through service.
         */
        async getInterview(context, qid) {
            try {
                const url = `${process.env.VUE_APP_CAWI_BASE_URL}/${qid}/api.pl?token=${axios.defaults.headers.common.Authorization.replace('Bearer ', '')}&action=getStatus`
                const res = await axios.get(url)
                return res.data
            } catch (err) {
                return console.log(err)
            }
        },

        ////////////////////////
        /// Validate address ///
        ////////////////////////

        /**
         * Show/hide the download popup.
         */
        toggleAddressChangePopup({ commit }, display) {
            commit('setPopupDisplayAddress', display)
        },

        //////////////////////
        /// Download popup ///
        //////////////////////

        /**
         * Show/hide the download popup.
         */
        toggleDownloadPopup({ commit }, { display, qid, isFile }) {
            commit('setPopupDisplay', display)
            commit('setDownloadId', qid)
            commit('setIsPdf', isFile)
        },
        /**
         * Create a new token for analysis result
         */
        async sendDownloadCode({ state }) {
            try {
                const res = await axios.post('/send-download-code', { qid: state.downloadId })
                return res.status === 200
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Create a new token for analysis result
         */
        async checkDownloadCode({ state }, code) {
            try {
                const res = await axios.post('/check-download-code', { qid: state.downloadId, code })
                return res.data
            } catch (err) {
                return err.response.data
            }
        },
        /**
         * Get CAWI CW_track with limited life duration
         */
        async getDownloadCwtrack({ state }, token) {
            try {
                const url = `${process.env.VUE_APP_CAWI_BASE_URL}/${state.downloadId}/api.pl?token=${token}&action=getDownload`
                const res = await axios.get(url)
                return res.data
            } catch (err) {
                return console.log(err)
            }
        },
    },
})

<template>
    <div class="page page--center">
        <Header logo-size="xl" />
        <main class="page__main">
            <div class="content">
                <div class="content__body">
                    <RecoveryForm />
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import RecoveryForm from '@/components/RecoveryForm.vue'

export default {
    name: 'Recovery',
    components: {
        Header,
        Footer,
        RecoveryForm,
    },
}
</script>

<template>
    <div v-if="address && displayPopupAddress == true" class="popup">
        <div class="popup-inner" style="max-width: 882px">
            <h5 class="card-title h4 mb-4 text-primary">Veuillez vérifier votre adresse</h5>

            <p style="font-weight: 700">
                {{ address.adr_l4 }} <span style="font-weight: 200" v-if="address.adr_l5"> ({{ address.adr_l5 }}) </span>,
                {{ address.adr_l6
                }}<span v-if="address.adr_l7">, {{ address.adr_l7 }} </span>
                <span style="font-weight: 200" v-if="address.adr_l2 || address.adr_l3"> ( {{ address.adr_l2 }} {{
                    address.adr_l3 }} )</span>
            </p>

            <p>
                En cas de changement d'adresse, veuillez contacter le numéro suivant 0805 02 02 63 ou envoyer un email à
                contact@constances.fr, en précisant votre numéro Constances, votre ancienne
                adresse et la nouvelle.
            </p>

            <button class="btn btn-success rounded-pill popup-confirm" @click="correctAddress()">Mon adresse actuelle est
                correcte</button>
            <button class="btn btn-success rounded-pill popup-confirm" @click="hidePopup()">J'ai appelé le numéro
                vert</button>
            <button class="btn btn-secondary rounded-pill popup-close" @click="hidePopup()">Me faire un rappel</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'Popup',
    data: () => ({
        cawiBaseUrl: process.env.VUE_APP_CAWI_BASE_URL,
    }),
    computed: {
        ...mapState(['displayPopupAddress', 'address']),
    },
    methods: {
        ...mapActions(['toggleAddressChangePopup', 'correctAddress']),
        hidePopup() {
            const delay = 24 * 60 * 60 * 1000 // 1 day
            this.saveDisplayState(false, delay)
            this.toggleAddressChangePopup(false)
        },
        async correctAddress() {
            const delay = 182 * 24 * 60 * 60 * 1000 // 182 days (6 months)
            this.saveDisplayState(false, delay)
            this.toggleAddressChangePopup(false)
            //ne pas envoyer de rappel avant x mois
        },
        saveDisplayState(state, time) {
            const now = new Date()

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const display = {
                value: state,
                expiry: now.getTime() + time,
            }
            localStorage.setItem('display', JSON.stringify(display))
        },
    },
    mounted() {
        const now = new Date()

        const itemStr = localStorage.getItem('display')
        if (!itemStr) {
            this.toggleAddressChangePopup(true)
            return
        }

        const item = JSON.parse(itemStr)

        if (now.getTime() > item.expiry) {
            this.toggleAddressChangePopup(true)
        } else {
            this.toggleAddressChangePopup(false)
        }
    },
}
</script>

<style scoped lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;

    .popup-inner {
        border-radius: 15px;
        background: #fff;
        padding: 2rem;
    }
}

.popup-confirm {
    margin-right: 15px;
    padding: 10px;
}

.popup-close {
    border-radius: 15px;
    padding: 10px;
}
</style>

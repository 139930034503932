<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Activation du compte <small class="fs-n1">(étape 2/3)</small></h2>
            <form @submit.prevent="validateSettings">
                <div class="mb-3">
                    <label for="email">E-mail</label>
                    <input type="email" id="email" v-model="email" class="form-control" placeholder="Votre adresse e-mail" required autocomplete="new-password" />
                </div>

                <div class="mb-3">
                    <label for="email-confirm">Confirmation de l'e-mail</label>
                    <input type="email" id="email-confirm" v-model="emailConfirm" class="form-control" placeholder="Confirmation de l'e-mail" required autocomplete="new-password" />
                </div>

                <div class="mb-3">
                    <label for="password">Mot de passe </label>
                    <div class="input-group">
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            id="password"
                            v-model="password"
                            class="form-control"
                            maxlength="16"
                            placeholder="Votre mot de passe"
                            required
                            autocomplete="new-password"
                        />
                        <span class="input-group-text" @click="togglePassword"><i :class="'bi bi-eye-' + (showPassword ? 'slash-' : '') + 'fill'"></i></span>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="password-confirm">Confirmation du mot de passe </label>
                    <div class="input-group">
                        <input
                            :type="showConfirm ? 'text' : 'password'"
                            id="password-confirm"
                            v-model="passwordConfirm"
                            class="form-control"
                            maxlength="16"
                            placeholder="Confirmation du mot de passe"
                            required
                            autocomplete="new-password"
                        />
                        <span class="input-group-text" @click="toggleConfirm"><i :class="'bi bi-eye-' + (showConfirm ? 'slash-' : '') + 'fill'"></i></span>
                    </div>
                </div>

                <PasswordHelp />

                <div
                    :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                    role="alert"
                    v-html="alert ? alert.message : '&nbsp;'"
                ></div>

                <div v-if="!alert || alert.class !== 'success'" class="mt-4 mb-2 text-center">
                    <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Valider</button>
                </div>

                <div class="text-center">
                    <router-link to="/activation" class="card-link fs-n1">Retour</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import PasswordHelp from '@/components/PasswordHelp.vue'

import Tools from '../tools'

export default {
    name: 'AccountForm',
    components: {
        PasswordHelp,
    },
    mixins: [Tools],
    data: () => ({
        email: '',
        emailConfirm: '',
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showConfirm: false,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
        confirmEmail() {
            return this.email === this.emailConfirm
        },
        confirmPassword() {
            return this.password === this.passwordConfirm
        },
    },
    methods: {
        ...mapActions(['sendAccountSettings']),
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        toggleConfirm() {
            this.showConfirm = !this.showConfirm
        },
        async validateSettings() {
            if (!this.confirmEmail) {
                this.alert = { class: 'warning', message: "Mauvaise confirmation de l'e-mail" }
            } else if (!this.checkPassword(this.password)) {
                this.alert = { class: 'warning', message: 'Votre mot de passe ne respecte pas les critères' }
            } else if (!this.confirmPassword) {
                this.alert = { class: 'warning', message: 'Mauvaise confirmation du mot de passe' }
            } else {
                this.loading = true
                this.alert = await this.sendAccountSettings({
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                })
                this.loading = false
            }
        },
    },
}
</script>


<template>
    <div v-if="displayPopup" class="popup">
        <div class="popup-inner">
            <h5 class="card-title h4 mb-4 text-primary">Téléchargement de votre document</h5>

            <div v-if="alert" :class="'alert alert-' + alert.class + ' text-center p-2 mt-2 fs-n1'" role="alert"
                v-html="alert.message"></div>

            <div v-else-if="displayStep === 0" class="mt-3">
                <p>Cliquez sur le bouton ci-dessous pour recevoir un code d'accès par e-mail.</p>
                <button class="btn btn-primary rounded-pill" @click="send()" :disabled="sendLock">
                    {{ sendLock ? 'Code envoyé récemment.Patientez...' : 'Envoyer un code' }}</button>
                <div class="mt-4">
                    <button class="btn btn-secondary rounded-pill popup-close" @click="hidePopup()">Annuler</button>
                </div>
            </div>

            <div v-if="displayStep === 1" class="mt-3">
                <p id="confirmation_message">
                    Saisissez le code d'accès qui vous a été envoyé par e-mail.<br /><small class="text-muted">Code
                        valable durant 5 mn. 3 essais possibles. Pensez à vérifier dans vos spams.</small>
                </p>
                <input type="text" v-model="authCode" id="authcode" name="authcode" class="form-control w-auto mb-4"
                    maxlength="6" size="12" />
                <button class="btn btn-success rounded-pill popup-confirm" @click="verify()">Valider</button>
                <button class="btn btn-secondary rounded-pill popup-close" @click="hidePopup()">Annuler</button>
            </div>

            <div v-else-if="displayStep === 2" class="mt-4">
                <button class="btn btn-secondary rounded-pill popup-close" @click="hidePopup()">Fermer</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    name: 'Popup',
    data: () => ({
        displayStep: 0,
        sendLock: false,
        authCode: '',
        alert: null,
        cawiBaseUrl: process.env.VUE_APP_CAWI_BASE_URL,
        apiBaseUrl: process.env.VUE_APP_API_URL,
    }),
    computed: {
        ...mapState(['displayPopup', 'downloadId', 'isFile']),
        ...mapGetters(['getUser']),
    },
    methods: {
        ...mapActions(['toggleDownloadPopup', 'sendDownloadCode', 'checkDownloadCode', 'getDownloadCwtrack']),
        hidePopup() {
            this.toggleDownloadPopup({ display: false })
            this.alert = null
            this.displayStep = 0
            this.authCode = ''
            this.docUrl = null
        },
        async send() {
            this.alert = null
            const ret = await this.sendDownloadCode()
            if (ret === true) {
                this.displayStep = 1
                this.errorMessage = ''

                // lock button during 20s (also see /send-download-code route)
                this.sendLock = true
                setTimeout(() => {
                    this.sendLock = false
                }, 20000)
            } else {
                this.alert = ret
                this.displayStep = 0
            }
        },
        async verify() {
            this.alert = null
            let ret = await this.checkDownloadCode(this.authCode)
            if (ret.token) {
                let docUrl = null

                if (this.isFile) {
                    /// TODO: handle more extensions than .pdf
                    docUrl = `${this.apiBaseUrl}/download-file/${this.downloadId}/${ret.token}/${this.downloadId}_${this.getUser.nconstances}.pdf`
                } else {
                    ret = await this.getDownloadCwtrack(ret.token)
                    if (ret.CW_track) {
                        this.displayStep = 2
                        docUrl = `${this.cawiBaseUrl}/${this.downloadId}/${this.downloadId}.pdf?CW_track=${ret.CW_track}`
                    }
                }

                if (docUrl) {
                    this.alert = {
                        class: 'success',
                        message: `Votre document va s'ouvrir automatiquement.<br>Sinon, cliquez sur <a href="${docUrl}" onclick="window.open(this.href);return false">ce lien pour le télécharger</a>.`,
                    }
                    setTimeout(() => window.open(docUrl), 3000)
                } else {
                    this.alert = { class: 'success', message: 'Une erreur est survenue.' }
                }
            } else {
                this.alert = ret
            }
        },
    },
}
</script>

<style scoped lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;

    .popup-inner {
        border-radius: 15px;
        background: #fff;
        padding: 2rem;
    }
}

#authcode {
    letter-spacing: 0.5em;
}

.popup-confirm {
    margin-right: 15px;
    padding: 10px;
}

.popup-close {
    border-radius: 15px;
    padding: 10px;
}

.error_message {
    color: red;
    font-size: 30px;
}
</style>

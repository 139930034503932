<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary">Modifier votre e-mail</h2>

            <form @submit.prevent="changeEmail">
                <div class="row gx-4">
                    <div class="mb-3 col-md-6">
                        <label for="new-email">Nouvelle adresse e-mail</label>
                        <input type="email" id="new-email" v-model="email" class="form-control" placeholder="Votre adresse e-mail" required autocomplete="new-password" />
                    </div>
                </div>

                <div class="row gx-4">
                    <div class="mb-3 col-md-6">
                        <label for="new-email-confirm">Confirmation de l'e-mail</label>
                        <input type="email" id="new-email-confirm" v-model="emailConfirm" class="form-control" placeholder="Confirmation de l'e-mail" required autocomplete="new-password" />
                    </div>
                </div>

                <div class="row gx-4">
                    <div class="col-md-6">
                        <div
                            :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 fs-n1' + (!alert ? ' invisible' : '')"
                            role="alert"
                            v-html="alert ? alert.message : '&nbsp;'"
                        ></div>
                    </div>
                </div>

                <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Modifier</button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'ChangeEmailForm',
    data: () => ({
        email: '',
        emailConfirm: '',
        loading: false,
        alert: null,
    }),
    computed: {
        confirmEmail() {
            return this.email === this.emailConfirm
        },
    },
    methods: {
        ...mapActions(['sendNewEmail']),
        async changeEmail() {
            if (!this.confirmEmail) {
                this.alert = { class: 'warning', message: "Mauvaise confirmation de l'e-mail" }
            } else {
                this.alert = await this.sendNewEmail({
                    email: this.email,
                })
                if (this.alert.class === 'success') {
                    this.email = ''
                    this.emailConfirm = ''
                }
            }
        },
    },
}
</script>


<template>
    <div class="page page--center">
        <Header logo-size="xl" />
        <main class="page__main">
            <div class="content">
                <div class="content__body">
                    <div class="card border-light rounded-3 shadow-lg">
                        <div class="card-body">
                            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Page non trouvée</h2>

                            <div class="'alert alert-danger text-center p-2" role="alert">La page que vous cherchez à atteindre n'existe pas.</div>

                            <div class="text-center mt-3">
                                <router-link to="/" class="card-link fs-n1">Retour</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'NotFound',
    components: {
        Header,
        Footer,
    },
}
</script>

<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Authentification</h2>
            <form @submit.prevent="signIn">
                <div class="mb-3">
                    <label for="username">Numéro Constances [8 chiffres]</label>
                    <input type="text" id="username" v-model="num" class="form-control" maxlength="8" pattern="[0-9]{8}" placeholder="Votre identifiant à 8 chiffres" required @keyup="formatNum" />
                </div>

                <div class="mb-3">
                    <label for="password">Mot de passe </label>
                    <div class="input-group">
                        <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" class="form-control" maxlength="16" placeholder="Votre mot de passe" required />
                        <span class="input-group-text" @click="togglePassword"><i :class="'bi bi-eye-' + (showPassword ? 'slash-' : '') + 'fill'"></i></span>
                    </div>
                </div>

                <div
                    :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                    role="alert"
                    v-html="alert ? alert.message : '&nbsp;'"
                ></div>

                <div class="mt-4 mb-2 text-center">
                    <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Se connecter</button>
                </div>

                <div class="text-center">
                    <input type="checkbox" id="stay-connected" v-model="remember" class="form-check-input" checked />
                    <label class="form-check-label ps-1 fw-light" for="stay-connected">Rester connecté</label>
                </div>

                <div class="mt-4 text-center">
                    <router-link to="/mot-de-passe" class="card-link fs-n1">Mot de passe oublié</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'LoginForm',
    data: () => ({
        num: '',
        password: '',
        remember: false,
        showPassword: false,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
    },
    methods: {
        ...mapActions(['logIn']),
        formatNum() {
            this.num = this.num.replace(/\D/, '')
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        async signIn() {
            this.alert = null
            const ret = await this.logIn({
                login: this.num,
                password: this.password,
                remember: this.remember,
            })
            if (this.isAuth) {
                this.$router.push('/suivi')
            } else {
                this.alert = ret
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.input-group-text {
    cursor: pointer;
}
</style>
<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary">Vos questionnaires en cours</h2>
            <div v-if="loading">Chargement...</div>
            <ul v-else-if="availableQuestionnaires && availableQuestionnaires.length" class="list-group list-group-flush">
                <li class="list-group-item d-none d-md-block">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b>Nom de l'étude</b>
                        </div>
                        <div class="col-12 col-md-6">
                            <b>Dates d'ouverture</b>
                        </div>
                    </div>
                </li>
                <li v-for="q in availableQuestionnaires" :key="q.id" class="list-group-item">
                    <div class="row">
                        <div class="col-12 col-md-6 d-flex align-items-center">{{ q.title }}</div>
                        <div
                            class="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                            <small class="mt-2 mt-md-0">{{ formatDate(q.startDate) }} - <span
                                    :class="warnDate(q.endDate) ? 'text-danger' : ''">{{ formatDate(q.endDate)
                                    }}</span></small>
                            <a :href="`${cawiBaseUrl}/${q.id}/${q.id}.pl?login=${q.cw_track}`"
                                class="btn btn-primary btn-sm rounded-pill mt-2 mt-md-0">Je participe</a>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else-if="availableQuestionnaires !== false">Aucun questionnaire disponible actuellement.</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Tools from '../tools'

export default {
    name: 'AvailableQuestionnaires',
    mixins: [Tools],
    data: () => ({
        cawiBaseUrl: process.env.VUE_APP_CAWI_BASE_URL,
    }),
    computed: {
        ...mapState(['loading', 'questionnaires']),
        availableQuestionnaires() {
            if (this.questionnaires !== null) {
                return this.questionnaires.filter((q) => !q.isAnalysis && !q.complete && !q.closed)
            }
            return false
        },
    },
}
</script>

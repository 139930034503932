<template>
    <div class="page">
        <Header disconnect="true" />

        <main class="page__main">
            <div class="content">
                <div class="content__header">
                    <div class="content__icon">
                        <span class="icon icon--light"><img src="../assets/images/ic-member.svg" /></span>
                    </div>
                    <div class="content__title">
                        <h1 class="h5">Votre participation à Constances</h1>
                    </div>
                </div>
                <div class="content__body">
                    <ProfileForm :profile-link="true" />
                    <AvailableQuestionnaires />
                    <CompleteQuestionnaires />
                    <AnalysisResults />
                    <DownloadPopup />
                    <!--AddressChangePopup /-->
                </div>
            </div>
        </main>

        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import ProfileForm from '@/components/ProfileForm.vue'
import AvailableQuestionnaires from '@/components/AvailableQuestionnaires.vue'
import CompleteQuestionnaires from '@/components/CompleteQuestionnaires.vue'
import AnalysisResults from '@/components/AnalysisResults.vue'
import DownloadPopup from '@/components/DownloadPopup.vue'
import AddressChangePopup from '@/components/AddressChangePopup.vue'

import Tools from '../tools'

export default {
    name: 'Dashboard',
    components: {
        Header,
        Footer,
        ProfileForm,
        AvailableQuestionnaires,
        CompleteQuestionnaires,
        AnalysisResults,
        DownloadPopup,
        AddressChangePopup,
    },
    mixins: [Tools],
    methods: {
        ...mapActions(['getQuestionnaires']),
    },
    async mounted() {
        await this.getQuestionnaires()
        ///this.initExtLinks()
    },
}
</script>

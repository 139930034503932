<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Activation du compte <small class="fs-n1">(étape 1/3)</small></h2>
            <form @submit.prevent="submitActivation">
                <div class="mb-3">
                    <label for="username">Numéro Constances [8 chiffres]</label>
                    <input type="text" id="username" v-model="num" class="form-control" maxlength="8" placeholder="Votre identifiant à 8 chiffres" required @keyup="formatNum" />
                </div>

                <div class="mb-3">
                    <label for="birthdate">Date de naissance</label>
                    <input type="date" id="birthdate" v-model="birthdate" class="form-control" required />
                </div>

                <div class="mb-3">
                    <label for="gender">Sexe</label>
                    <select id="gender" v-model="gender" class="form-control" required>
                        <option value="2">Féminin</option>
                        <option value="1">Masculin</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label for="center">CES du 1<sup>er</sup> bilan de santé</label>
                    <select id="center" v-model="attachCenter" class="form-control" required>
                        <option v-for="center in attachCenters" :key="center.id_centre" :value="center.id_centre">{{ center.nom }}</option>
                    </select>
                </div>

                <vue-recaptcha ref="recaptcha" class="d-flex justify-content-center" :sitekey="captchaKey" @verify="verifyCaptcha" @error="captchaError"></vue-recaptcha>

                <div
                    :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                    role="alert"
                    v-html="alert ? alert.message : '&nbsp;'"
                ></div>

                <div class="mt-4 mb-2 text-center">
                    <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Valider</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'ActivationForm',
    components: {
        VueRecaptcha,
    },
    data: () => ({
        num: null,
        birthdate: null,
        gender: null,
        attachCenter: null,
        attachCenters: [],
        captchaKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
        captchaToken: null,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
        validNum() {
            return this.num.length === 8 && !isNaN(this.num)
        },
    },
    methods: {
        ...mapActions(['getAttachCenters', 'activateAccount']),
        formatNum() {
            this.num = this.num.replace(/\D/, '')
        },
        async submitActivation() {
            if (!this.validNum) {
                this.alert = { class: 'warning', message: 'Mauvais format du n° Constances' }
            } else {
                this.alert = null
                const ret = await this.activateAccount({
                    login: this.num,
                    birthdate: this.birthdate,
                    gender: this.gender,
                    attachCenter: this.attachCenter,
                    captcha: this.captchaToken,
                })
                if (ret.token) {
                    this.$router.push(`/activation/${ret.token}`)
                } else {
                    this.alert = ret
                    this.$refs.recaptcha.reset()
                }
            }
        },
        verifyCaptcha(payload) {
            this.captchaToken = payload
        },
        captchaError() {
            this.alert = { class: 'danger', message: "Echec de l'acquisition CAPTCHA" }
        },
    },
    async mounted() {
        this.attachCenters = await this.getAttachCenters()
    },
}
</script>

<style lang="scss" scoped>
select {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
}
</style>
<template>
    <header class="page__header">
        <nav class="nav">
            <div class="nav__home">
                <router-link v-if="home" to="/suivi" title="Retour à l'accueil">
                    <span class="icon"><img src="../assets/images/ic-home.svg" /></span>
                </router-link>
            </div>
            <div class="nav__logo">
                <a href="https://www.constances.fr/">
                    <img :class="logoSize ? `logo logo--${logoSize}` : ''" src="../assets/images/logo.svg" />
                </a>
            </div>
            <div class="nav__connect">
                <a v-if="disconnect" href="/" title="Se déconnecter" @click="signOut">
                    <span class="icon"><img src="../assets/images/ic-logout.svg" /></span>
                </a>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Header',
    props: ['logo-size', 'home', 'disconnect'],
    methods: {
        ...mapActions(['logOut']),
        signOut() {
            this.$router.push('/')
            this.logOut()
        },
    },
}
</script>
export default {
    methods: {
        initExtLinks() {
            document.querySelectorAll('a.newtab').forEach(function (elt) {
                elt.addEventListener('click', function (evt) {
                    evt.preventDefault()
                    window.open(this.href)
                })
            })
        },
        formatInclusion(period) {
            if (!period) {
                return ''
            }
            const m = period.toString().match(/(\d{4})(\d)/) // YYYYS
            return (m[2] === '1' ? 'Premier' : 'Second') + ' semestre ' + m[1]
        },
        checkPassword(password) {
            return password.length >= 8 && password.length <= 16 && password.match(/[a-z]/) !== null && password.match(/[A-Z]/) !== null && password.match(/[0-9]/) !== null
        },
        formatDate(date) {
            if (date) {
                const d = new Date(date)
                const txt = ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear()
                return txt
            }
            return '-'
        },
        warnDate(date) {
            const today = new Date()
            const cmpDate = new Date(date)
            return cmpDate - today < 60 * 24 * 60 * 60 * 1000 // 60 days in milliseconds
        },
    },
}

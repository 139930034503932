import { createRouter, createWebHistory } from 'vue-router'

import Activation1 from '../views/Activation1.vue'
import Activation2 from '../views/Activation2.vue'
import Activation3 from '../views/Activation3.vue'

import Login from '../views/Login.vue'
import Recovery from '../views/Recovery.vue'
import Reset from '../views/Reset.vue'

import Dashboard from '../views/Dashboard.vue'
import Profile from '../views/Profile.vue'
import Email from '../views/Email.vue'

import NotFound from '../views/NotFound.vue'

import store from '../store'

const routes = [
    {
        path: '/activation',
        name: 'Activation1',
        component: Activation1,
    },
    {
        path: '/activation/:token',
        name: 'Activation2',
        component: Activation2,
    },
    {
        path: '/a/:token',
        name: 'Activation3',
        component: Activation3,
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            auth: false,
        },
    },
    {
        path: '/mot-de-passe',
        name: 'Recovery',
        component: Recovery,
        meta: {
            auth: false,
        },
    },
    {
        path: '/r/:token',
        name: 'Reset',
        component: Reset,
    },
    {
        path: '/suivi',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            auth: true,
        },
    },
    {
        path: '/profil',
        name: 'Profile',
        component: Profile,
        meta: {
            auth: true,
        },
    },
    {
        path: '/e/:token',
        name: 'Email',
        component: Email,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// Handle redirections
router.beforeEach((to, from, next) => {
    store.dispatch('initAuth').then(() => {
        const isAuth = store.getters['isAuth']
        if (to.matched.some((route) => route.meta.auth) && !isAuth) {
            ///console.log('REDIRECT TO LOGIN')
            next({ name: 'Login' })
        } else if (to.matched.some((route) => route.meta.auth === false) && isAuth) {
            ///console.log('REDIRECT TO DASHBOARD')
            next({ name: 'Dashboard' })
        } else {
            next()
        }
    })
})

export default router

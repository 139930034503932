<template>
    <div class="page page--center">
        <Header logo-size="xl" />
        <main class="page__main">
            <div class="content">
                <div class="content__body">
                    <div class="card border-light rounded-3 shadow-lg">
                        <div class="card-body">
                            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Activation du compte <small class="fs-n1">(étape 3/3)</small></h2>

                            <div
                                :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                                role="alert"
                                v-html="alert ? alert.message : '&nbsp;'"
                            ></div>

                            <div v-if="alert && alert.class === 'success'" class="mt-4 mb-2 text-center">
                                <router-link to="/">Se connecter</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Activation3',
    components: {
        Header,
        Footer,
    },
    data: () => ({
        alert: null,
    }),
    methods: {
        ...mapActions(['confirmActivation']),
    },
    async mounted() {
        this.alert = await this.confirmActivation({ token: this.$route.params.token })
    },
}
</script>

<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary">Modifier votre mot de passe</h2>

            <form @submit.prevent="changePassword">
                <div class="row gx-4">
                    <div class="col-md-6">
                        <label for="current-password">Mot de passe actuel</label>
                        <div class="input-group">
                            <input
                                :type="showPassword ? 'text' : 'password'"
                                id="current-password"
                                v-model="currentPassword"
                                class="form-control"
                                maxlength="16"
                                placeholder="Votre mot de passe"
                                required
                                autocomplete="new-password"
                            />
                            <span class="input-group-text" @click="togglePassword"><i :class="'bi bi-eye-' + (showPassword ? 'slash-' : '') + 'fill'"></i></span>
                        </div>
                    </div>
                </div>

                <div class="row gx-4 align-items-end">
                    <div class="col-12 col-md-6">
                        <label for="new-password" class="mt-3">Nouveau mot de passe</label>
                        <div class="input-group">
                            <input
                                :type="showNewPassword ? 'text' : 'password'"
                                id="new-password"
                                v-model="newPassword"
                                class="form-control"
                                maxlength="16"
                                placeholder="Votre nouveau mot de passe"
                                required
                                autocomplete="new-password"
                            />
                            <span class="input-group-text" @click="toggleNewPassword"><i :class="'bi bi-eye-' + (showNewPassword ? 'slash-' : '') + 'fill'"></i></span>
                        </div>

                        <label for="new-password-confirm" class="mt-3">Confirmez le nouveau mot de passe</label>
                        <div class="input-group">
                            <input
                                :type="showPasswordConfirm ? 'text' : 'password'"
                                id="new-password-confirm"
                                v-model="newPasswordConfirm"
                                class="form-control"
                                maxlength="16"
                                placeholder="Confirmer le nouveau mot de passe"
                                required
                                autocomplete="new-password"
                            />
                            <span class="input-group-text" @click="togglePasswordConfirm"><i :class="'bi bi-eye-' + (showPasswordConfirm ? 'slash-' : '') + 'fill'"></i></span>
                        </div>
                    </div>

                    <div class="col-auto col-md-6 mt-3">
                        <PasswordHelp />
                    </div>
                </div>

                <div class="row gx-4">
                    <div class="mt-3 col-md-6">
                        <div
                            :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 fs-n1' + (!alert ? ' invisible' : '')"
                            role="alert"
                            v-html="alert ? alert.message : '&nbsp;'"
                        ></div>
                    </div>
                </div>

                <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Modifier</button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import PasswordHelp from '@/components/PasswordHelp.vue'

import Tools from '../tools'

export default {
    name: 'ChangePasswordForm',
    components: {
        PasswordHelp,
    },
    mixins: [Tools],
    data: () => ({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        showPassword: false,
        showNewPassword: false,
        showPasswordConfirm: false,
        loading: false,
        alert: null,
    }),
    computed: {
        confirmNewPassword() {
            return this.newPassword === this.newPasswordConfirm
        },
    },
    methods: {
        ...mapActions(['updatePassword']),
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        toggleNewPassword() {
            this.showNewPassword = !this.showNewPassword
        },
        togglePasswordConfirm() {
            this.showPasswordConfirm = !this.showPasswordConfirm
        },
        async changePassword() {
            if (!this.checkPassword(this.newPassword)) {
                this.alert = { class: 'warning', message: 'Votre mot de passe ne respecte pas les critères' }
            } else if (!this.confirmNewPassword) {
                this.alert = { class: 'warning', message: 'Mauvaise confirmation du mot de passe' }
            } else if (this.currentPassword === this.newPassword) {
                this.alert = { class: 'warning', message: "Votre nouveau mot de passe est identique à l'ancien" }
            } else {
                this.alert = await this.updatePassword({
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                })
                if (this.alert.class === 'success') {
                    this.currentPassword = ''
                    this.newPassword = ''
                    this.newPasswordConfirm = ''
                }
            }
        },
    },
}
</script>


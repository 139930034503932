<template>
    <div id="passwordHelpInline" class="card-text">
        <i class="bi bi-question-circle"></i> <span class="small">Le mot de passe doit contenir :</span>
        <ul class="small">
            <li>entre 8 et 16 caractères</li>
            <li>une minuscule</li>
            <li>une majuscule</li>
            <li>un chiffre</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'PasswordHelp',
}
</script>

<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Mot de passe oublié</h2>

            <form @submit.prevent="recoverPassword">
                <div class="mb-3">
                    <label class="form-control-placeholder" for="username">Numéro Constances [8 chiffres]</label>
                    <input type="text" id="username" v-model="num" class="form-control" maxlength="8" pattern="[0-9]{8}" placeholder="Votre identifiant à 8 chiffres" required @keyup="formatNum" />
                </div>

                <div class="mb-3">
                    <label class="form-control-placeholder" for="email">E-mail</label>
                    <input type="text" id="email" v-model="email" class="form-control" placeholder="Votre adresse e-mail" required />
                </div>

                <vue-recaptcha ref="recaptcha" class="d-flex justify-content-center" :sitekey="captchaKey" @verify="verifyCaptcha" @error="captchaError"></vue-recaptcha>

                <div
                    :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                    role="alert"
                    v-html="alert ? alert.message : '&nbsp;'"
                ></div>

                <div v-if="!alert || alert.class !== 'success'" class="mt-4 mb-2 text-center">
                    <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Réinitialiser</button>
                </div>

                <div class="text-center">
                    <router-link to="/" class="card-link fs-n1">Retour</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { VueRecaptcha } from 'vue-recaptcha'

export default {
    name: 'RecoveryForm',
    components: {
        VueRecaptcha,
    },
    data: () => ({
        num: '',
        email: '',
        captchaKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
        captchaToken: null,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
    },
    methods: {
        ...mapActions(['resetPassword']),
        formatNum() {
            this.num = this.num.replace(/\D/, '')
        },
        async recoverPassword() {
            this.loading = true
            this.alert = await this.resetPassword({
                login: this.num,
                email: this.email,
                captcha: this.captchaToken,
            })
            this.loading = false
            this.$refs.recaptcha.reset()
            if (this.alert.class === 'success') {
                this.num = ''
                this.email = ''
            }
        },
        verifyCaptcha(payload) {
            this.captchaToken = payload
        },
        captchaError() {
            this.alert = { class: 'danger', message: "Echec de l'acquisition CAPTCHA" }
        },
    },
}
</script>

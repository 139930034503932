<template>
    <div class="card border-light rounded-3 shadow-lg">
        <div class="card-body">
            <h2 class="card-title h4 mb-4 text-primary text-center text-md-start">Nouveau mot de passe</h2>

            <form @submit.prevent="resetPassword">
                <div class="mb-3">
                    <label for="password">Mot de passe </label>
                    <div class="input-group">
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            id="password"
                            v-model="password"
                            class="form-control"
                            maxlength="16"
                            placeholder="Votre nouveau mot de passe"
                            required
                            autocomplete="new-password"
                        />
                        <span class="input-group-text" @click="togglePassword"><i :class="'bi bi-eye-' + (showPassword ? 'slash-' : '') + 'fill'"></i></span>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="password-confirm">Confirmation du mot de passe </label>
                    <div class="input-group">
                        <input
                            :type="showConfirm ? 'text' : 'password'"
                            id="password-confirm"
                            v-model="passwordConfirm"
                            class="form-control"
                            maxlength="16"
                            placeholder="Confirmation du mot de passe"
                            required
                            autocomplete="new-password"
                        />
                        <span class="input-group-text" @click="toggleConfirm"><i :class="'bi bi-eye-' + (showConfirm ? 'slash-' : '') + 'fill'"></i></span>
                    </div>
                </div>

                <PasswordHelp />

                <div
                    :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                    role="alert"
                    v-html="alert ? alert.message : '&nbsp;'"
                ></div>

                <div v-if="!alert || alert.class !== 'success'" class="mt-4 mb-2 text-center">
                    <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Enregistrer</button>
                </div>

                <div v-if="alert && alert.class === 'success'" class="text-center">
                    <router-link to="/" class="card-link fs-n1">Se connecter</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import PasswordHelp from '@/components/PasswordHelp.vue'

import Tools from '../tools'

export default {
    name: 'ResetForm',
    components: {
        PasswordHelp,
    },
    mixins: [Tools],
    data: () => ({
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showConfirm: false,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
        confirmPassword() {
            return this.password === this.passwordConfirm
        },
    },
    methods: {
        ...mapActions(['sendNewPassword']),
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        toggleConfirm() {
            this.showConfirm = !this.showConfirm
        },
        async resetPassword() {
            if (!this.checkPassword(this.password)) {
                this.alert = { class: 'warning', message: 'Votre mot de passe ne respecte pas les critères' }
            } else if (!this.confirmPassword) {
                this.alert = { class: 'warning', message: 'Mauvaise confirmation du mot de passe' }
            } else {
                this.alert = await this.sendNewPassword({
                    token: this.$route.params.token,
                    password: this.password,
                })
                if (this.alert.class === 'success') {
                    this.password = ''
                    this.passwordConfirm = ''
                }
            }
        },
    },
}
</script>
